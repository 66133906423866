import React, { useContext, useState, useEffect } from "react";
import {
  Accordion,
  Card,
  Breadcrumb,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "@StarberryUtils";
import { StarBerry } from "./icon";
import Instagram_black from "../../images/instagram_black.svg"
import Facebook_black from "../../images/facebook.svg"
import Twitter_black from "../../images/twitter_black.svg"
import Youtube_black from "../../images/youtube_black.svg"
import PropOmbudsLogo from "../../images/prop-ombuds-white.svg"
import RicsLogo from "../../images/rics.svg"
import CookieConsent from "../../components/CookieConsent/CookieConsent"
import PopularSearch from "./PopularSearch/PopularSearch"
import PopularSearchResults from "./PopularSearch/SearchResult"
import PopularSearchDetails from "./PopularSearch/PropertyDetail"
import PopularSearchAreaDetails from "./PopularSearch/AreaDetails"

import OurOffice from "./OurOffice.js"
import { GetURL, lazyLoader } from "../Common/site/functions";
import HTMLReactParser from "html-react-parser";
import _ from "lodash"

// images

// Style
import "../Footer/Footer.scss";
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={
        isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
      }
      onClick={decoratedOnClick}
    >
      {children}
      <span className={isCurrentEventKey ? "d-none" : "d-block"}>+</span>
      <span className={isCurrentEventKey ? "d-block " : "d-none"}>-</span>
    </div>
  );
}
const Footer = (props) => {

  const data = useStaticQuery(graphql`
  query {
      glstrapi {
        offices (sort:"Sort:asc", where: {Publish: true}){         
            Name
            URL
            Tile_Image {
              alternativeText
              url
            }
            Video_URL
            Address
            Mobile
        }
        siteConfig {
          Facebook_Link
          Instagram_Link
          Twitter_Link
          Youtube_Link  
          Footer_Links {
            Label
            Link {
              id
            }
          }
          Footer_Logos {
            Image {
              url
              alternativeText
            }
            URL
          }
        }
        popularSearch {
          Title   
        }
    }
  }
`);

  const officeList = data.glstrapi.offices;
  const siteConfig = data.glstrapi.siteConfig;
  const search = data.glstrapi.popularSearch;

  const [showSearch, setShowSearch] = useState(true);

  const displaySearch = () => {
    setShowSearch(!showSearch)
  }
  const [lazyLoad, setLazyLoad] = useState(true);
  useEffect(() => {
    // lazyLoader(window, () => {
    //   setLazyLoad(true)
    // })
  }, [])



  return (
    <React.Fragment>
      <CookieConsent />
      <footer className="footer">
        <Container>
          <Row>
            <Col lg="12" className="d-lg-none">
              <div className="footer-accordion">
                <Accordion>
                  <div className="accordion-card">
                    <div className="accordion-header">
                      <ContextAwareToggle
                        eventKey="0"
                        className="accordion-header"
                      >
                        {search.Title}
                      </ContextAwareToggle>
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-card accordion-card-first">
                        {/* <PopularSearch popular_search={props.popularSearch} /> */}
                        {props.popularSearchType === "static" && props.popularSearch &&
                            <Col xs={12}>
                                <div className="popular-search-list">
                                    <PopularSearch popular_search={props.popularSearch} />
                                </div>
                            </Col>
                        }
                        {props.popularSearchType === "propertyresults" &&
                            <Col xs={12}>
                                <div className="popular-search-list">
                                    <PopularSearchResults searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} SearchPrice={props.SearchPrice} />
                                </div>
                            </Col>
                        }
                        {props.popularSearchType === "propertydetails" &&
                            <Col xs={12}>
                                <div className="popular-search-list">
                                    <PopularSearchDetails propertydetails={props.propertydetails} />
                                </div>
                            </Col>
                        }
                        {props.popularSearchType === "areadetails" &&
                            <Col xs={12}>
                              {props.title}
                                <div className="popular-search-list">
                                    <PopularSearchAreaDetails title={props.title} />
                                </div>
                            </Col>
                            }
                      </div>
                    </Accordion.Collapse>
                  </div>
                  <div className="accordion-card">
                    <div className="accordion-header">
                      <ContextAwareToggle
                        eventKey="1"
                        className="accordion-header"
                      >
                        Our Offices
                      </ContextAwareToggle>
                    </div>

                    <Accordion.Collapse eventKey="1">
                      <div className="accordion-card accordion-card-first">
                        <OurOffice data={officeList} />
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
            </Col>
            <Col lg="7" xl="8" className="d-none d-lg-block">
              <OurOffice data={officeList} />
            </Col>
            <Col lg="5" xl="4">
              <div className="right-side-box">
                <div className="d-md-flex">
                  <div className="d-flex social_icon_bx order-2">
                    {siteConfig.Facebook_Link &&
                      <a href={siteConfig.Facebook_Link} target="_blank">
                        <img src={Facebook_black} alt="Facebook logo" />
                      </a>
                    }
                    {siteConfig.Twitter_Link &&
                      <a href={siteConfig.Twitter_Link} target="_blank">
                        <img src={Twitter_black} alt="Twitter logo" />
                      </a>
                    }
                    {siteConfig.Instagram_Link &&
                      <a href={siteConfig.Instagram_Link} target="_blank">
                        <img src={Instagram_black} alt="Instagram logo" />
                      </a>
                    }
                    {siteConfig.Youtube_Link &&
                      <a href={siteConfig.Youtube_Link} target="_blank">
                        <img src={Youtube_black} alt="Youtube logo" />
                      </a>
                    }
                  </div>
                  {lazyLoad && 
                      <div className="logo-brand order-1 d-lg-none">
                        <Row className="align-items-center">
                          {
                            siteConfig.Footer_Logos && siteConfig.Footer_Logos.map((logo, i) => {
                              return (
                                <Col lg="6" xs={6} className="my-3 footer-list-logo" key={i}>
                                  {logo.Image &&
                                    <a href={`${logo.URL}`} target="_blank"><img className="m-0" src={logo.Image.url} alt={`Client Logo  - DBRoberts`} /></a>
                                  }
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </div>
                  }
                </div>
                <div className="footer_info_link">
                  <ul>

                    {siteConfig.Footer_Links && siteConfig.Footer_Links.map((item, i) => {
                      let url = (!_.isEmpty(item.Link)) ? GetURL(item.Link.id) : '';
                      return (
                        <li key={i}>
                          <Link to={`/${url[0]}`}>{item.Label}</Link>
                        </li>)
                    })}
                    <li>
                      <a href="javascript:void(0);" id="open_preferences_center" >Update cookies preferences</a>
                    </li>
                  </ul>
                </div>
                <p>© DB Roberts Estate Agents <br /> <a href="https://starberry.tv/" rel="noopener" target="_blank" className="starberry-logo">Site by <StarBerry /></a></p>
                {lazyLoad && siteConfig.Footer_Logos &&
                  <div className="logo-brand d-none d-lg-flex">
                    <Row className="align-items-center">
                      {
                        siteConfig.Footer_Logos.map((logo, i) => {
                          return (
                            <Col lg="6" xs={6} key={i}>
                              {logo.Image &&
                                <a href={`${logo.URL}`} target="_blank"><img src={logo.Image.url} alt={`Client Logo  - DBRoberts`}/></a>
                              }
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </div>
                }
              </div>
            </Col>
            {props.popularSearchType === "static" && props.popularSearch &&
              <Col xs={12} className="d-none d-lg-block">
                <div className="popular-search-list">
                  <h4 onClick={e => displaySearch()}>
                    Popular Searches <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                  </h4>
                  <div className={showSearch ? "d-none" : ""}>
                    <PopularSearch popular_search={props.popularSearch} />
                  </div>
                </div>
              </Col>
            }
            {props.popularSearchType === "propertyresults" &&
              <Col xs={12} className="d-none d-lg-block">
                <div className="popular-search-list">
                  <h4 onClick={e => displaySearch()}>
                    Popular Searches <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                  </h4>
                  <div className={showSearch ? "d-none" : ""}>
                    <PopularSearchResults searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} SearchPrice={props.SearchPrice} />
                  </div>
                </div>
              </Col>
            }
            {props.popularSearchType === "propertydetails" &&
              <Col xs={12} className="d-none d-lg-block">
                <div className="popular-search-list">
                  <h4 onClick={e => displaySearch()}>
                    Popular Searches <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                  </h4>
                  <div className={showSearch ? "d-none" : ""}>
                    <PopularSearchDetails propertydetails={props.propertydetails} />
                  </div>
                </div>
              </Col>
            }
            {props.popularSearchType === "areadetails" &&
              <Col xs={12} className="d-none d-lg-block">
                <div className="popular-search-list">
                  <h4 onClick={e => displaySearch()}>
                    Popular Searches <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                  </h4>
                  <div className={showSearch ? "d-none" : ""}>
                    <PopularSearchAreaDetails title={props.title} />
                  </div>
                </div>
              </Col>
            }



          </Row>
        </Container>
      </footer>
    </React.Fragment >

  )
}
export default Footer;
